import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { TermsComponent } from './components/terms/terms.component';

const marketingRoutes: Routes = [
	{ 
		path: '', 
		component: HomeComponent,
		pathMatch: 'full'
	},
	{ 
		path: 'contact-us', 
		component: ContactUsComponent,
		pathMatch: 'full'
	},
	{ 
		path: 'terms-of-use', 
		component: TermsComponent,
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(marketingRoutes)],
	exports: [RouterModule]
})

export class MarketingRoutingModule { }
