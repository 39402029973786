import { Component } from '@angular/core';

@Component({
	selector: 'app-contact',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {
	constructor() { }

	lat: number = 25.790348;
	lng: number = -80.131629;
	zoom: number = 13;

}
