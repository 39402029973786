import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';

import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { TermsComponent } from './components/terms/terms.component';

@NgModule({
	declarations: [
		ContactUsComponent,
		HomeComponent,
		TermsComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBtijSGRCTxkGodpYuNKU7pDEeixMAksyQ'
		})
	]
})
export class MarketingModule { }