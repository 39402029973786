import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { MarketingRoutingModule } from './marketing/marketing-routing.module';
import { MarketingModule } from './marketing/marketing.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		MarketingRoutingModule,
		MarketingModule,
		SharedModule,
		CommonModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBtijSGRCTxkGodpYuNKU7pDEeixMAksyQ'
		})
	],
	bootstrap: [AppComponent]
})

export class AppModule { }