import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	showNavigation = false;

	constructor() { }

	ngOnInit() {
	}

	navigationToggle() {
		this.showNavigation = !this.showNavigation;
	}

}
