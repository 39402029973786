import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	activeTab: number = 1;

	constructor() { }

	ngOnInit() {
	}

	onChangeTab(id) {
		this.activeTab = id;
	}

}
